import * as THREE from 'three';
import fragmentShader from './shaders/fragment-bk.glsl';
// Scene setup
const scene = new THREE.Scene();
const vMouse = new THREE.Vector2();
const vMouseDamp = new THREE.Vector2();
const vResolution = new THREE.Vector2();

// Viewport setup (updated on resize)
let w, h = 1;

// Orthographic camera setup
const aspect = w / h;
const camera = new THREE.OrthographicCamera(-aspect, aspect, 1, -1, 0.1, 1000);

const renderer = new THREE.WebGLRenderer();
renderer.domElement.classList.add('webgl');
document.querySelector("#app").appendChild(renderer.domElement);

renderer.setClearColor(0x000000, 0);
renderer.domElement.style.backgroundColor = 'transparent';

const onPointerMove = (e) => { vMouse.set(e.clientX, e.clientY) }
document.addEventListener('mousemove', onPointerMove);
//document.addEventListener('pointermove', onPointerMove);
//document.body.addEventListener('touchmove', function (e) { e.preventDefault(); }, { passive: false });

const getTheme = () => {
  const theme = document.documentElement.getAttribute('data-theme');
  return theme === 'dark' ? 1 : 0;  // Return 1 for dark theme, 0 for light theme
}

// Plane geometry covering the full viewport
const geo = new THREE.PlaneGeometry(1, 1);  // Scaled to cover full viewport

// Shader material creation
const mat = new THREE.ShaderMaterial({
  vertexShader: /* glsl */`
    varying vec2 v_texcoord;
    void main() {
        gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
        v_texcoord = uv;
    }`,
  fragmentShader, // most of the action happening in the fragment
  uniforms: {
    u_mouse: { value: vMouseDamp },
    u_resolution: { value: vResolution },
    u_pixelRatio: { value: 2 },
    u_theme: { value: getTheme() }
  }
});

// Mesh creation
const quad = new THREE.Mesh(geo, mat);
scene.add(quad);

// Camera position and orientation
camera.position.z = 1;  // Set appropriately for orthographic

// Animation loop to render
let time, lastTime = 0;
const update = () => {
  // calculate delta time
  time = performance.now() * 0.001;
  const dt = time - lastTime;
  lastTime = time;

  // ease mouse motion with damping
  for (const k in vMouse) {
    if (k == 'x' || k == 'y') vMouseDamp[k] = THREE.MathUtils.damp(vMouseDamp[k], vMouse[k], 8, dt);
  }

  // render scene
  requestAnimationFrame(update);
  renderer.render(scene, camera);
};
update();

// Funzione per rimuovere il renderer dal DOM
const removeRenderer = () => {
  if (renderer.domElement.parentNode) {
    renderer.domElement.parentNode.removeChild(renderer.domElement);
  }
};

// Funzione per inizializzare nuovamente il renderer
const initializeRenderer = () => {
  const existingCanvas = document.querySelector("#app canvas.webgl");
  const newCanvas = renderer.domElement;

  if (existingCanvas) {
    // Inserisce il nuovo canvas sopra il canvas esistente
    document.querySelector("#app").insertBefore(newCanvas, existingCanvas);
  } else {
    // Se non c'è un canvas esistente, aggiungi semplicemente il nuovo canvas
    document.querySelector("#app").appendChild(newCanvas);
  }

  newCanvas.classList.add('webgl');
  renderer.setClearColor(0x000000, 0);
  newCanvas.style.backgroundColor = 'transparent';
};

const resize = () => {
  w = window.innerWidth;
  h = window.innerHeight;

  const dpr = Math.min(window.devicePixelRatio, 2);

  renderer.setSize(w, h);
  renderer.setPixelRatio(dpr);

  camera.left = -w / 2;
  camera.right = w / 2;
  camera.top = h / 2;
  camera.bottom = -h / 2;
  camera.updateProjectionMatrix();

  quad.scale.set(w, h, 1);
  vResolution.set(w, h).multiplyScalar(dpr);
  mat.uniforms.u_pixelRatio.value = dpr;

  // Aggiungi o rimuovi il renderer in base alla larghezza della finestra
  if (w < 824) {
    removeRenderer();
  } else {
    initializeRenderer();
  }
};

resize(); // Chiamata iniziale per impostare correttamente il renderer

window.addEventListener('resize', resize);

// Listen for theme changes
const observer = new MutationObserver((mutations) => {
  mutations.forEach((mutation) => {
    if (mutation.attributeName === 'data-theme') {
      mat.uniforms.u_theme.value = getTheme();
    }
  });
});

observer.observe(document.documentElement, {
  attributes: true,
});