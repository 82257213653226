import GSAP from 'gsap';

export default class {
  constructor(element, camera) {
    this.element = element

    this.camera = camera

    this.animateIn()
  }

  animateIn() {
    const animateIn = GSAP.timeline({ 
      defaults: {
        ease: 'expo'
      }
    })
    
    animateIn
      .from(this.camera.position, {
        z: 12,
        stagger: .2,
        delay: 4,
        duration: 3
      })

    }
}
