import * as THREE from 'three';
import GSAP from 'gsap';

import Animations from './Animations';
import SmoothScroll from './SmoothScroll';

import vertexShader from './shaders/vertex.glsl';
import fragmentShader from './shaders/fragment.glsl';

class ScrollStage {
  constructor() {
    this.element = document.querySelector('#scroll_stage');
    this.elements = {
      line: this.element.querySelector('.scroll_content')
    };

    this.viewport = {
      width: window.innerWidth,
      height: window.innerHeight,
    };

    this.mouse = {
      x: 0,
      y: 0
    };

    this.scroll = {
      height: 0,
      limit: 0,
      hard: 0,
      soft: 0,
      ease: 0.05,
      normalized: 0,
      running: false
    };

    this.settings = {
      // vertex
      uFrequency: { start: 9, end: 3 },
      uAmplitude: { start: 5, end: 8 },
      uDensity: { start: 1, end: 1 },
      uStrength: { start: 0.1, end: 1 },
      // fragment
      uDeepPurple: { start: 0.66, end: 0 },
      uOpacity: { start: 0.66, end: 0.66 },
      uNoiseScale: { start: 15.0, end: 15.0 },
      uNoiseAmount: { start: 0.9, end: 0.9 },
      uLineFrequency: { start: 3.0, end: 3.0 },
      uLineAmplitude: { start: 6.5, end: 6.5 }
    };

    this.scene = new THREE.Scene();

    this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    this.canvas = this.renderer.domElement;

    this.camera = new THREE.PerspectiveCamera(75, this.viewport.width / this.viewport.height, 0.1, 10);
    this.clock = new THREE.Clock();

    this.smoothScroll = new SmoothScroll({
      element: this.element,
      viewport: this.viewport,
      scroll: this.scroll
    });

    GSAP.defaults({
      ease: 'power2',
      duration: 2.5,
      overwrite: true
    });

    this.updateScrollAnimations = this.updateScrollAnimations.bind(this);
    this.update = this.update.bind(this);

    this.init();
  }

  init() {
    this.addCanvas();
    this.addCamera();
    this.addMesh();
    this.addEventListeners();
    this.onResize();
    this.update();
  }

  addCanvas() {
    this.canvas.classList.add('webgl');
    document.querySelector("#app").appendChild(this.canvas);
  }

  addCamera() {
    this.camera.position.set(0, 0, 2.5);
    this.scene.add(this.camera);
  }

  addMesh() {
    this.geometry = new THREE.IcosahedronGeometry(1, 64);

    this.material = new THREE.ShaderMaterial({
      blending: THREE.CustomBlending,
      blendEquation: THREE.AddEquation,
      blendSrc: THREE.SrcAlphaFactor,
      blendDst: THREE.OneMinusSrcAlphaFactor,
      transparent: true,
      vertexShader,
      fragmentShader,
      uniforms: {
        uFrequency: { value: this.settings.uFrequency.start },
        uAmplitude: { value: this.settings.uAmplitude.start },
        uDensity: { value: this.settings.uDensity.start },
        uStrength: { value: this.settings.uStrength.start },
        uDeepPurple: { value: this.settings.uDeepPurple.start },
        uOpacity: { value: this.settings.uOpacity.start },
        uNoiseScale: { value: this.settings.uNoiseScale.start },
        uNoiseAmount: { value: this.settings.uNoiseAmount.start },
        uLineFrequency: { value: this.settings.uLineFrequency.start },
        uLineAmplitude: { value: this.settings.uLineAmplitude.start }
      }
    });

    this.mesh = new THREE.Mesh(this.geometry, this.material);
    this.scene.add(this.mesh);
  }

  updateScrollAnimations() {
    this.scroll.running = false;
    this.scroll.normalized = this.scroll.hard / this.scroll.limit;

    GSAP.to(this.mesh.rotation, {
      x: this.scroll.normalized * Math.PI,
      y: (this.scroll.normalized * Math.PI) + 6
    });

    for (const key in this.settings) {
      if (this.settings[key].start !== this.settings[key].end) {
        GSAP.to(this.mesh.material.uniforms[key], {
          value: this.settings[key].start + this.scroll.normalized * (this.settings[key].end - this.settings[key].start)
        });
      }
    }
  }

  addEventListeners() {
    window.addEventListener('load', this.onLoad.bind(this));
    window.addEventListener('scroll', this.onScroll.bind(this));
    window.addEventListener('resize', this.onResize.bind(this));
  }

  onLoad() {
    this.animations = new Animations(this.element, this.camera);
  }

  onMouseMove(event) {
    this.mouse.x = 0.8 * (event.clientX / this.viewport.width - 0.5);
    this.mouse.y = 0.1 * (event.clientY / this.viewport.height - 0.5);
  }

  onScroll() {
    if (!this.scroll.running) {
      window.requestAnimationFrame(this.updateScrollAnimations);
      this.scroll.running = true;
    }
  }

  onResize() {
    this.viewport = {
      width: window.innerWidth,
      height: window.innerHeight
    };

    this.isMobile = this.viewport.width <= 767;
    this.isTablet = this.viewport.width >= 768 && this.viewport.width < 1024;
    this.isLaptop = this.viewport.width >= 1024 && this.viewport.width < 1600;
    this.isDesktop = this.viewport.width >= 1600;

    this.smoothScroll.onResize();

    this.mesh.scale.set(this.isMobile ? 0.5 : 1, this.isMobile ? 0.5 : 1, this.isMobile ? 0.5 : 1);

    if (!this.isMobile) {
      window.addEventListener("mousemove", this.onMouseMove.bind(this));
    }

    this.camera.aspect = this.viewport.width / this.viewport.height;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(this.viewport.width, this.viewport.height);
    this.renderer.setPixelRatio(window.devicePixelRatio);
  }

  update() {
    const elapsedTime = this.clock.getElapsedTime();
    this.mesh.rotation.y = 0.05 * elapsedTime + this.mouse.x;
    this.mesh.rotation.x = 0.05 * elapsedTime + this.mouse.y;

    this.smoothScroll.update();
    this.render();

    window.requestAnimationFrame(this.update);
  }

  render() {
    this.renderer.render(this.scene, this.camera);
  }
}

new ScrollStage();
