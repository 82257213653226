import Lenis from '@studio-freight/lenis';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const fxTitles = [...document.querySelectorAll('.item_link')];

// Lenis smooth scrolling
let lenis;

// Initialize Lenis smooth scrolling
const initSmoothScrolling = () => {
	// Instantiate the Lenis object with specified properties
	lenis = new Lenis({
		lerp: 0.2, // Lower values create a smoother scroll effect
		smoothWheel: true // Enables smooth scrolling for mouse wheel events
	});

	// Update ScrollTrigger each time the user scrolls
	lenis.on('scroll', () => ScrollTrigger.update());

	// Define a function to run at each animation frame
	const scrollFn = (time) => {
		lenis.raf(time); // Run Lenis' requestAnimationFrame method
		requestAnimationFrame(scrollFn); // Recursively call scrollFn on each frame
	};
	// Start the animation frame loop
	requestAnimationFrame(scrollFn);
};

const contentElements = [...document.querySelectorAll('.s_item')];
const totalContentElements = contentElements.length;

const scroll = () => {
	contentElements.forEach((el, position) => {
        const isLast = position === totalContentElements - 0;
        gsap.timeline({
            scrollTrigger: {
                trigger: el,
                start: 'center center',
                end: '+=100%',
                scrub: true
            }
        }).to(el, {
            ease: 'none',
            scale: isLast ? 1 : 0.6,
            opacity: 0,
            yPercent: isLast ? 125 : 0
        }, 0);
    });
	
	//console.log(contentElements[totalContentElements - 1]);
	
	ScrollTrigger.create({
		trigger: contentElements[totalContentElements - 1], // Trigger sull'ultimo elemento
		start: 'bottom bottom', // Controlla quando l'elemento è in fondo alla finestra
		end: 'bottom top', // Controlla quando l'ultimo elemento è nella parte superiore della finestra
		markers: false,
		onToggle: self => {
			if (self.isActive) { // Controlla se l'elemento è visibile nella finestra
				gsap.to('#footer', {
					scale: 1,
					autoAlpha: 1,
					duration: 0.2,
					ease: 'none'
				}).play(); // Avvia l'animazione
			} else {
				gsap.to('#footer', {
					scale: 0.6,
					autoAlpha: 0,
					duration: 0.2,
					ease: 'none'
				}).play(); // Avvia l'animazione
			}
		}
	});
};

// Lenis (smooth scrolling)
initSmoothScrolling();
// GSAP Scroll Triggers
scroll();